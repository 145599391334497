import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    transactionNo: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    containerNo1: validateStringField(
      14,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    containerNo2: validateStringField(
      14,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    containerNo3: validateStringField(
      14,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    flightNo: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    trailerNo: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    isDairy: Yup.string().nullable(),
    isProcessFoodVeg: Yup.string().nullable(),
    isHoney: Yup.string().nullable(),
    isMapleProd: Yup.string().nullable(),
    isSeeds: Yup.string().nullable(),
    isFeed: Yup.string().nullable(),
    isFortizer: Yup.string().nullable()
  })
}

export default makeValidationSchema
