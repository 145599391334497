import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexDigit = '^[0-9]*$'
  let errMsgDigit = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_NUMERIC
  )
  // const errMsgDigit = 'Invalid Value - Allowed Digits'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    additionDeductionInd: Yup.string().nullable(),
    additionDeductionCode: Yup.string().nullable(),
    amountRatePercent: validateStringField(10, regexDigit, errMsgDigit),
    additionDeductionType: Yup.string().nullable(),
    explanation: validateStringField(22, regexAlphaNumeric, errMsgAlphaNumeric)
  })
}

export default makeValidationSchema
