import React, { useRef, useEffect } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import FormProperties from './InvoiceSummaryFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Divider, Grid, Tooltip } from '@material-ui/core'
import InvoiceSummaryFieldsPlaceholder from './InvoiceSummaryFieldsPlaceholder'
import CngSection from '../../../components/cngcomponents/CngSection'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function InvoiceSummarySection({ invoiceData, isPendingSave, isPendingSaveShipmentSummary, invoiceStateData, isNotSaved }) {
  const { getValues, setValue, watch } = useFormContext()
  const invoiceSummary = watch('invSummary')
  const invoiceDetails = watch('invDetails')
  const generatedIndex = useRef(0)
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  const calculateTotal = () => {
    let lineAmountTotal = 0;
    let TotalInvSum=0;
    let totalValAdd = 0;
    let totalValDedd = 0;
    if (invoiceDetails) {
      invoiceDetails.forEach((obj) => {
        const lineAmountvalue = parseFloat(obj.lineItemTotal);
        lineAmountTotal += lineAmountvalue;
      });
    }
    if(invoiceSummary.length>0){
      if(invoiceSummary[0].shipmentSummaryReference.length>0){
      invoiceSummary[0].shipmentSummaryReference.forEach((obj) => {
        if (obj.additionDeductionType == "P") {
          if (obj.additionDeductionInd == "C") {
              var divisor = obj.amountRatePercent/100;  
              var actualAmount = lineAmountTotal * (divisor);
              totalValAdd= parseFloat(totalValAdd) + parseFloat(actualAmount);
              }else if (obj.additionDeductionInd == "A") {
                var divisor = obj.amountRatePercent/100;  
                var actualAmount = lineAmountTotal * (divisor);
              totalValDedd=parseFloat(totalValDedd)+parseFloat(actualAmount);
             }
             } else if (obj.additionDeductionType == "A"){
             if (obj.additionDeductionInd == "C") {
           totalValAdd = parseFloat(totalValAdd)+parseFloat(obj.amountRatePercent);
             } else if (obj.additionDeductionInd == "A") {
           totalValDedd = parseFloat(totalValDedd)+parseFloat(obj.amountRatePercent);    
         }
         }
      });
    }
  }
    TotalInvSum=parseFloat(lineAmountTotal)+parseFloat(totalValAdd)-parseFloat(totalValDedd);      
    console.log("Total Invoice Amount" + TotalInvSum );

    console.log("invoiceSummary size: " + invoiceSummary);
    if (invoiceData.current.invSummary && invoiceData.current.invSummary.length > 0) {
      invoiceData.current.invSummary.forEach((obj1) => {
        obj1.totalInvoice = parseFloat(TotalInvSum.toFixed(2));
      });
      setValue('invSummary', invoiceData.current.invSummary)
      // invoiceData.current.invSummary = invoiceSummary
    } else {
      const newInvoiceSummary = [...getValues('invSummary'),
      { "totalInvoice": "", "shipmentSummary": [], "shipmentSummaryReference": [] }]
      newInvoiceSummary.forEach((obj1) => {
        obj1.totalInvoice = parseFloat(TotalInvSum.toFixed(2));
      });
      setValue('invSummary', newInvoiceSummary)
      invoiceData.current.invSummary = newInvoiceSummary
      console.log("New invSummary: " + invoiceSummary);
    }
  };

  useEffect(() => { //for invoice detail
    calculateTotal();
  }, [invoiceStateData,invoiceSummary]);

  function makeTranslatedTextsObject() {
    const invoiceSummary = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.InvSummary.TITLE
    )

    return { invoiceSummary }
  }

  function handleAddInvoiceSummary(data) {
    const invoiceSummary = [...getValues('invSummary'), data].map(
      (invoice, index) => ({ ...invoice, _id: index })
    )

    setValue('invSummary', invoiceSummary)
    invoiceData.current.invSummary = invoiceSummary
    isNotSaved.current = true
  }

  function getClonedInvoiceSummary(invoiceSummary) {
    let result = { ...invoiceSummary, id: undefined }

    result.shipmentSummary.forEach((shipment) => {
      shipment['id'] = undefined
    })

    result.shipmentSummaryReference.forEach((reference) => {
      reference['id'] = undefined
    })

    isNotSaved.current = true
    return result
  }

  function handleDeleteInvoiceSummary(index) {
    const invoiceSummary = [...getValues('invSummary')]

    invoiceSummary.splice(index, 1)

    setValue('invSummary', invoiceSummary)
    invoiceData.current.invSummary = invoiceSummary
    isNotSaved.current = true
  }

  function handleEditInvoiceSummary(index, data) {
    const invoiceSummary = [...getValues('invSummary')]

    invoiceSummary[index] = data

    setValue('invSummary', invoiceSummary)
    invoiceData.current.invSummary = invoiceSummary
    isNotSaved.current = true
  }

  return (
    <CngSection title={translatedTextsObject.invoiceSummary}>
      <Grid container spacing={2}>
        {_.isEmpty(invoiceSummary) ? (
          <Grid item xs={12}>
            <InvoiceSummaryFieldsPlaceholder onAdd={handleAddInvoiceSummary} />
          </Grid>
        ) : (
            invoiceSummary.map((summary, index) => (
              <React.Fragment key={++generatedIndex.current}>
              <Grid item xs={12}>
                <InvoiceSummaryEntry
                  invoiceSummary={summary}
                  onAdd={handleAddInvoiceSummary}
                  onClone={(data) =>
                    handleAddInvoiceSummary(getClonedInvoiceSummary(data))
                  }
                  onDelete={() => handleDeleteInvoiceSummary(index)}
                  onEdit={(data) => handleEditInvoiceSummary(index, data)}
                  isPendingSave={isPendingSave}
                  isPendingSaveShipmentSummary={isPendingSaveShipmentSummary}
                />
              </Grid>
              {index + 1 !== invoiceSummary.length && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                )}
              </React.Fragment>
          ))
        )}
      </Grid>
    </CngSection>
  )
}

function InvoiceSummaryEntry(props) {
  const { invoiceSummary, onAdd, onClone, onDelete, onEdit, isPendingSave, isPendingSaveShipmentSummary } = props
  
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const methods = useForm({
    defaultValues: invoiceSummary,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditInvoiceSummary(data) {
    methods.reset(data)
    onEdit(data)
  }

  useEffect(() => {
    isPendingSave.current = isDirty
  }, [isDirty])

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields isPendingSaveShipmentSummary={isPendingSaveShipmentSummary} />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditInvoiceSummary)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() => onClone(invoiceSummary)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(invoiceSummary)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default InvoiceSummarySection
