import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexDigitWithDecimal = ValidationRegex.regexDigitWithDecimal
  let errMsgDigitWithDecimal = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_NUMERIC_DECIMAL
  )
  // const errMsgDigitWithDecimal =
  //   'Invalid Value - Allowed Digits with/without decimal'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    totalInvoice: validateStringField(
      20,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    shipmentSummary: Yup.array(),
    shipmentSummaryReference: Yup.array()
  })
}

export default makeValidationSchema
