import React, { useRef } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../../constants/locale/key/NBCICanadaInvoice'
import FormProperties from './ServicePromotionFormProperties'
import ServicePromotionFieldsPlaceholder from './ServicePromotionFieldsPlaceholder'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function ServicePromotionOtherChargesSection() {
  const generatedIndex = useRef(0)
  const { getValues, setValue, watch } = useFormContext()

  const servicePromotion = watch('servicePromotion')

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const servicePromotion = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ServicePromotion.TITLE
    )

    return { servicePromotion }
  }

  function handleAddServicePromotion(data) {
    const servicePromotion = [...getValues('servicePromotion'), data]

    setValue('servicePromotion', servicePromotion)
  }

  function handleDeleteServicePromotion(index) {
    const servicePromotion = [...getValues('servicePromotion')]

    servicePromotion.splice(index, 1)

    setValue('servicePromotion', servicePromotion)
  }

  function handleEditServicePromotion(index, data) {
    const servicePromotion = [...getValues('servicePromotion')]

    servicePromotion[index] = data

    setValue('servicePromotion', servicePromotion)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }}>
          {translatedTextsObject.servicePromotion}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          {_.isEmpty(servicePromotion) ? (
            <Grid item xs={12}>
              <ServicePromotionFieldsPlaceholder
                onAdd={handleAddServicePromotion}
              />
            </Grid>
          ) : (
            servicePromotion.map((item, index) => (
              <React.Fragment key={++generatedIndex.current}>
                <Grid item xs={12}>
                  <ServicePromotionEntry
                    servicePromotion={item}
                    onAdd={handleAddServicePromotion}
                    onDelete={() => handleDeleteServicePromotion(index)}
                    onEdit={(data) => handleEditServicePromotion(index, data)}
                  />
                </Grid>
                {index + 1 !== servicePromotion.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

function ServicePromotionEntry(props) {
  const { servicePromotion, onAdd, onDelete, onEdit } = props

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const methods = useForm({
    defaultValues: servicePromotion,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditServicePromotion(data) {
    methods.reset(data)
    onEdit(data)
  }

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditServicePromotion)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() =>
                          onAdd({ ...servicePromotion, id: undefined })
                        }
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(servicePromotion)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ServicePromotionOtherChargesSection
