import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import moment from 'moment'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import UsProductMasterKeys from 'src/constants/locale/key/UsProductMaster.js'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let errMsgAlphaNumericHyphenUnderscore = translate(
    Namespace.US_PRODUCT_MASTER,
    UsProductMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_HYPHEN_UNDERSCORE
  )
  let errMsgAlphaNumeric = translate(
    Namespace.US_PRODUCT_MASTER,
    UsProductMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC
  )
  let errMsgAlphaNumericPartNo = translate(
    Namespace.US_PRODUCT_MASTER,
    UsProductMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_PARTNO
  )
  let errMsgAlphaNumericItemDesc = translate(
    Namespace.US_PRODUCT_MASTER,
    UsProductMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_ITEM_DESC
  )
  let errMsgAlphaNumericSpecChar = translate(
    Namespace.US_PRODUCT_MASTER,
    UsProductMasterKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )

  return Yup.object({
    productId: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1')
      .max(50, 'Max length allowed is 50')
      .required(requiredMessage)
      .matches(
        '[a-zA-Z0-9\\-\\_\\ ]$',
        errMsgAlphaNumericHyphenUnderscore
      )
      .nullable(),
    manufacturingPartNo: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1')
      .max(25, 'Max length allowed is 25')
      .required(requiredMessage)
      .test(
        'Check Product / Part Number',
        errMsgAlphaNumericPartNo,
        function () {
          let manufacturingPartNo = this.parent['manufacturingPartNo']
          var format = /[{}&@*=+\\|$;:!\[\]]/
          if (manufacturingPartNo) {
            if (!format.test(manufacturingPartNo)) {
              return true
            }
          }
        }
      )
      .nullable(),
    itemDescription: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1')
      .max(160, 'Max length allowed is 160')
      .required(requiredMessage)
      .test(
        'Check Product Description',
        errMsgAlphaNumericItemDesc,
        function () {
          let itemDescription = this.parent['itemDescription']
          var format = /[<>*~\[\]]/
          if (itemDescription) {
            if (!format.test(itemDescription)) {
              return true
            }
          }
        }
      )
      .nullable(),
    productClassificationCode: Yup.string()
      .trim()
      .min(1, 'Min length needed is 1')
      .max(8, 'Max length allowed is 8')
      .matches(
        '^\\d{2}[a-zA-Z]{4}\\d{1}$|^\\d{2}[a-zA-Z]{3}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{3}\\d{2}$|^\\d{2}[a-zA-Z]{5}$|^\\d{2}[a-zA-Z]{1}\\-{2}[a-zA-Z]{1}\\d{1}$|^\\d{2}[a-zA-Z]{1}\\-{2}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{1}\\-{2}[a-zA-Z]{2}$|^\\d{2}[a-zA-Z]{1}\\-{2}\\d{2}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{2}\\d{1}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{1}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{1}\\d{2}$|^\\d{2}[a-zA-Z]{1}\\-{1}[a-zA-Z]{3}$|^\\d{2}[a-zA-Z]{2}\\-{1}[a-zA-Z]{1}\\d{1}$|^\\d{2}[a-zA-Z]{2}\\-{1}\\d{1}[a-zA-Z]{1}$|^\\d{2}[a-zA-Z]{2}\\-{1}\\d{2}$|^\\d{2}[a-zA-Z]{2}\\-{1}[a-zA-Z]{2}$',
        'Correct format should be as ( NNAAAAN or NNAAANA or NNAAANN or NNAAAAA or NNA--AN or NNA--NA or NNA--AA or NNA--NN or NNA-AAN or NNA-ANA or NNA-ANN or NNA-AAA or NNAA-AN or NNAA-NA or NNAA-NN or NNAA-AA )'
      ).nullable(),
    countryOfOrigin: Yup.string().trim().required(requiredMessage).nullable(),
    uom: Yup.string()
      .trim()
      .required(requiredMessage)
      .matches(/^[a-zA-Z0-9]+$/, errMsgAlphaNumeric)
      .nullable(),
    unitPrice: Yup.string()
      .trim()
      .max(19, 'Max length allowed is 19')
      .matches(
        /^\d{0,7}(\.\d{0,7})?$/,
        'Only numeric values with up to 7 digits and 7 decimal points'
      )
      .test(
        'Unit Price',
        'Unit Price value has to greater than 0.00',
        function () {
          let unitPrice = this.parent['unitPrice']
          var format = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/
          if (unitPrice !== undefined) {
            if (format.test(unitPrice)) {
              return true
            }
          } else {
            return true
          }
        }
      ),
    tariffNo: Yup.string()
      .trim()
      .max(10, 'Max length allowed is 10')
      .matches(
        /^\d+$/,
        'H.T.S. Tariff Number accepts only maximum of 10 digits'
      )
      .nullable(),
    markPackLoading: Yup.string().trim().max(80, 'Max length allowed is 80').matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar).nullable(),
    includeProduct: Yup.string()
      .when('countryOfOrigin', {
        is: 'US',
        then: Yup.string().required(requiredMessage),
        otherwise: Yup.string().when('countryOfOrigin', {
          is: 'CA',
          then: Yup.string().required(requiredMessage)
        })
      })
      .nullable(),
    isProducer: Yup.string()
      .when('includeProduct', {
        is: 'Y',
        then: Yup.string().required(requiredMessage)
      })
      .nullable(),
    // notAProducerOfGoods: Yup.string().when('isProducer', {
    //   is: 'N',
    //   then: Yup.string().required(
    //     'If USMCA Exporter is not Producer, Please specify any one option from the above'
    //   )
    // }),
    naftaCriteria: Yup.string().nullable(),
    netCost: Yup.string().nullable(),
    dateRangeFrom: Yup.string()
      .when('includeProduct', {
        is: 'Y',
        then: Yup.string()
          .required(requiredMessage)
          .test(
            'dateRangeFrom',
            'Please enter a valid Date Range From in DD/MM/YYYY format',
            function () {
              let dateValue = this.parent['dateRangeFrom']
              if (dateValue) {
                var formattedDate = moment(dateValue, 'DD/MM/YYYY', true)
                if (formattedDate.isValid()) {
                  return true
                }
              }
            }
          )
      })
      .nullable(),
    dateRangeTo: Yup.string()
      .when('includeProduct', {
        is: 'Y',
        then: Yup.string()
          .required(requiredMessage)
          .test(
            'dateRangeTo',
            'Please enter a valid Date Range To in DD/MM/YYYY format',
            function () {
              let dateValue = this.parent['dateRangeTo']
              if (dateValue) {
                var formattedDate = moment(dateValue, 'DD/MM/YYYY', true)
                if (formattedDate.isValid()) {
                  return true
                }
              }
            }
          )
      })
      .nullable(),
    manufacturingPartyName: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(60, 'Max length allowed is 60').nullable(),
    manufacturingMID: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(20, 'Max length allowed is 20').nullable(),
    manufacturingFDARegNum: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(60, 'Max length allowed is 60').nullable(),
    growerPartyName: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(60, 'Max length allowed is 60').nullable(),
    growerMID: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(20, 'Max length allowed is 20').nullable(),
    growerFDARegNumber: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(60, 'Max length allowed is 60').nullable(),
    consolidatorPartyName: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(60, 'Max length allowed is 60').nullable(),
    consolidatorMID: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(20, 'Max length allowed is 20').nullable(),
    consolidatorFDARegNumber: Yup.string()
      .trim()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(60, 'Max length allowed is 60').nullable(),
    usorcaMaster: Yup.string().required(requiredMessage).nullable(),
    hsCode: Yup.string()
      .matches(ValidationRegex.regexAlphaNumeric, errMsgAlphaNumericSpecChar)
      .max(15, 'Max length allowed is 15').nullable()
  })
}

export default makeValidationSchema
