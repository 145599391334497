import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexAlphaNum = '^[a-zA-Z0-9]*$'
  let errMsgAlphaNum = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC
  )
  // const errMsgAlphaNum = 'Invalid Value - Allowed Alphanumeric'
  const regexAlphaNumWithSlash = '^[a-zA-Z0-9/]*$'
  let errMsgAlphaNumWithSlash = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SLASH
  )
  // const errMsgAlphaNumWithSlash = 'Invalid Value - Allowed Alphanumeric and /'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    businessNo: validateStringField(15, regexAlphaNum, errMsgAlphaNum),
    exportLicenseNo: validateStringField(25, regexAlphaNum, errMsgAlphaNum),
    destinationCountry: validateStringField(
      25,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    transportDocno: validateStringField(
      25,
      regexAlphaNumWithSlash,
      errMsgAlphaNumWithSlash
    ),
    custProofReportNo: validateStringField(25, regexAlphaNum, errMsgAlphaNum),
    goodsInspected: Yup.string().nullable(),
    status: Yup.string().nullable(),
    vesselName: validateStringField(35, regexAlphaNumeric, errMsgAlphaNumeric),
    dateOfExport: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    noOfPkgs: validateStringField(10, regexAlphaNum, errMsgAlphaNum),
    pkgsType: Yup.string().nullable(),
    containerNo: validateStringField(14, regexAlphaNum, errMsgAlphaNum),
    frieghtChrgsToExit: validateStringField(
      4,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    exportRefNo: validateStringField(20, regexAlphaNumeric, errMsgAlphaNumeric),
    reasonForExport: validateStringField(
      20,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
