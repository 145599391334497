import { Yup } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexCharacters = '^[a-zA-Z]+$'
  let errMsgCharacters = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHABET
  )
  // const errMsgCharacters = 'Invalid Value - Allowed Alphabets'
  const regexAlphaNumWithHyphenDot = '^[a-zA-Z0-9-.]*$'
  let errMsgAlphaNumWithHyphenDot = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_HYPHEN_DOT
  )
  // const errMsgAlphaNumWithHyphenDot =
  //   'Invalid Value - Allowed Alphabetnumeric, digits, hyphen and dot'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    countryTransShipment: Yup.string().nullable(),
    countryOfOrigin: Yup.string().nullable(),
    placeOfDirectShipment: validateStringField(
      25,
      regexCharacters,
      errMsgCharacters
    ),
    alternativeInvNo: validateStringField(
      22,
      regexAlphaNumWithHyphenDot,
      errMsgAlphaNumWithHyphenDot
    ),
    customsRuling: validateStringField(
      50,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    transportTermCode: Yup.string().nullable(),
    termOfSale: validateStringField(80, regexAlphaNumeric, errMsgAlphaNumeric),
    royalityPayments: Yup.string().nullable(),
    royalityGoods: Yup.string().nullable()
  })
}

export default makeValidationSchema
