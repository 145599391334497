import { Yup } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexAlphaNumeric = ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexDigitWithDecimal = ValidationRegex.regexDigitWithDecimal
  let errMsgDigitWithDecimal = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_NUMERIC_DECIMAL
  )
  // const errMsgDigitWithDecimal =
  //   'Allowed Digits with/without decimal'
  const regexDigit = '^[0-9]*$'
  let errMsgDigit = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_NUMERIC
  )
  // const errMsgDigit = 'Invalid Value - Allowed Digits'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    noOfPkgs: validateStringField(10, regexDigit, errMsgDigit),
    pkgType: Yup.string().nullable(),
    pkgDimLength: validateStringField(
      7,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    pkgDimWidth: validateStringField(
      7,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    pkgDimHeight: validateStringField(
      7,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    pkgDimUom: Yup.string().nullable(),
    commodityDescription: validateStringField(
      30,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    grossWeight: validateStringField(
      9,
      regexDigitWithDecimal,
      errMsgDigitWithDecimal
    ),
    grossWeightUom: Yup.string().nullable(),
    freightClass: Yup.string().nullable()
  })
}

export default makeValidationSchema
