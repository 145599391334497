import React, { useRef, useEffect } from 'react'
import { components, useTranslation } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import FormProperties from './ShipmentSummaryFormProperties'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Box, Card, Divider, Grid, Tooltip } from '@material-ui/core'
import ShipmentSummaryFieldsPlaceholder from './ShipmentSummaryFieldsPlaceholder'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'

const {
  button: { CngButton, CngIconButton }
} = components

const { initialValues, makeValidationSchema } = FormProperties.formikProps

function ShipmentSummarySection({ isPendingSaveShipmentSummary }) {
  const { getValues, setValue, watch } = useFormContext()
  const shipmentSummaries = watch('shipmentSummary')
  const generatedIndex = useRef(0)

  function handleAddShipmentSummary(data) {
    const shipmentSummaries = [...getValues('shipmentSummary'), data]

    setValue('shipmentSummary', shipmentSummaries, { shouldDirty: true })
  }

  function handleDeleteShipmentSummary(index) {
    const shipmentSummaries = [...getValues('shipmentSummary')]

    shipmentSummaries.splice(index, 1)

    setValue('shipmentSummary', shipmentSummaries, { shouldDirty: true })
  }

  function handleEditShipmentSummary(index, data) {
    const shipmentSummaries = [...getValues('shipmentSummary')]

    shipmentSummaries[index] = data

    setValue('shipmentSummary', shipmentSummaries, { shouldDirty: true })
  }

  return (
    <Card variant='outlined'>
      <Box padding={1}>
        <Grid container spacing={2}>
          {_.isEmpty(shipmentSummaries) ? (
            <Grid item xs={12}>
              <ShipmentSummaryFieldsPlaceholder onAdd={handleAddShipmentSummary} />
            </Grid>
          ) : (
            shipmentSummaries.map((summary, index) => (
              <React.Fragment key={++generatedIndex.current}>
                <Grid item xs={12}>
                  <ShipmentSummaryEntry
                    shipmentSummaries={summary}
                    onAdd={handleAddShipmentSummary}
                    onDelete={() => handleDeleteShipmentSummary(index)}
                    onEdit={(data) => handleEditShipmentSummary(index, data)}
                    isPendingSaveShipmentSummary={isPendingSaveShipmentSummary}
                  />
                </Grid>
                {index + 1 !== shipmentSummaries.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </React.Fragment>
            ))
          )}
        </Grid>
      </Box>
    </Card>
  )
}

function ShipmentSummaryEntry(props) {
  const { shipmentSummaries, onAdd, onDelete, onEdit, isPendingSaveShipmentSummary } = props

  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const methods = useForm({
    defaultValues: shipmentSummaries,
    resolver: useCustomYupValidationResolver(makeValidationSchema(translate))
  })
  const {
    formState: { isDirty }
  } = methods

  function handleEditShipmentSummary(data) {
    methods.reset(data)
    onEdit(data)
  }

  useEffect(() => {
    isPendingSaveShipmentSummary.current = isDirty
  }, [isDirty])

  return (
    <FormProvider {...methods}>
      <Grid alignItems='center' container spacing={1}>
        <Grid item xs={12}>
          <FormProperties.Fields />
        </Grid>
        <Grid item xs={12}>
          <Grid justify='flex-end' container spacing={1}>
            {isDirty ? (
              <>
                <Grid item xs='auto'>
                  <CngButton
                    color='secondary'
                    onClick={() => methods.reset()}
                    size='medium'
                  >
                    Reset
                  </CngButton>
                </Grid>
                <Grid item xs='auto'>
                  <CngButton
                    color='primary'
                    onClick={methods.handleSubmit(handleEditShipmentSummary)}
                    size='medium'
                  >
                    Save changes
                  </CngButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Add'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'plus']}
                        onClick={() => onAdd(initialValues)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Clone'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'copy']}
                        onClick={() =>
                          onAdd({ ...shipmentSummaries, id: undefined })
                        }
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
                <Grid item xs='auto'>
                  <Tooltip placement='bottom' title='Delete'>
                    <span>
                      <CngIconButton
                        icon={['fal', 'trash']}
                        onClick={() => onDelete(shipmentSummaries)}
                        size='small'
                        type='outlined'
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  )
}

export default ShipmentSummarySection
