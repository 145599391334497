import { Yup } from 'cng-web-lib'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexAlphaNum = '^[a-zA-Z0-9]*$'
  let errMsgAlphaNum = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC
  )
  // const errMsgAlphaNum = 'Invalid Value - Allowed Alphanumeric'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    otherRefNo: validateStringField(30, regexAlphaNumeric, errMsgAlphaNumeric),
    responsibleForCustoms: validateStringField(
      20,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    instructionsToBroker: Yup.string().nullable(),
    containerized: Yup.string().nullable(),
    billChargesTo: Yup.string().nullable(),
    portOfLoading: validateStringField(4, regexAlphaNum, errMsgAlphaNum),
    flightDetails: validateStringField(20, regexAlphaNum, errMsgAlphaNum),
    placeOfDelivery: validateStringField(20, regexAlphaNum, errMsgAlphaNum),
    specialInstructions: validateStringField(
      200,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
